import React from 'react'

import Analytics from '../util/analytics'

import Jumbotron from '../components/Jumbotron/Jumbotron'
import Button from '../components/Button/Button'
import FlexBox, { Justification } from '../components/FlexBox/FlexBox'
import ContentContainer from '../components/ContentContainer/ContentContainer'
import TitledContent from '../components/TitledContent/TitledContent'
import Video from '../components/Video/Video'
import FullWidthSection, { PaddingOption } from '../components/FullWidthSection/FullWidthSection'
import Heading, { HeadingLevel, HeadingStyle } from '../components/Heading/Heading'
import Paragraph from '../components/Paragraph/Paragraph'
import MainLayout from '../layouts/MainLayout'
import { COMPANY_NAME } from '../constants'

import jumboImage from './Download/images/download-jumbotron-image.png'
import downloadImage from './Download/images/download-image.png'
import activateImage from './Download/images/activate-image.png'
import appStoreImage from './Download/images/app-store-image.png'
import googlePlayImage from './Download/images/google-play-image.png'
import linkImage from './Download/images/link-image.png'

import './Download/Download.scss'
import { DownloadPlatform } from '../util/GoogleAnalytics'

const Download: React.SFC = () => (
  <MainLayout title={`${COMPANY_NAME} – Download`}>
    <Jumbotron title="Get Started with Sonocent Audio Notetaker" image={jumboImage}>
      <Paragraph>Take your pick:</Paragraph>
      <FlexBox justify={Justification.NORMAL} className="download__jumbotron-buttons">
        <Button
          to="https://link.sonocent.com/AudioNotetaker/Windows/Download"
          className="download__jumbotron-buttons__button"
          onClick={() => Analytics.download(DownloadPlatform.WINDOWS)}
        >
          <FlexBox justify={Justification.CENTER} className="download__jumbotron-buttons__button__content">
            <div className="download__jumbotron-buttons__button__content__image download__jumbotron-buttons__button__content__image--windows" />
            <div className="download__jumbotron-buttons__button__content__text">
              <span className="download__jumbotron-buttons__button__content__text__title">Download</span>
              <span className="download__jumbotron-buttons__button__content__text__subtitle">
                <em>for Windows</em>
              </span>
            </div>
          </FlexBox>
        </Button>
        <Button
          to="https://link.sonocent.com/AudioNotetaker/Mac/Download"
          className="download__jumbotron-buttons__button"
          onClick={() => Analytics.download(DownloadPlatform.MAC)}
        >
          <FlexBox justify={Justification.CENTER} className="download__jumbotron-buttons__button__content">
            <div className="download__jumbotron-buttons__button__content__image download__jumbotron-buttons__button__content__image--mac" />
            <div className="download__jumbotron-buttons__button__content__text">
              <span className="download__jumbotron-buttons__button__content__text__title">Download</span>
              <span className="download__jumbotron-buttons__button__content__text__subtitle">
                <em>for Mac</em>
              </span>
            </div>
          </FlexBox>
        </Button>
      </FlexBox>
    </Jumbotron>

    <main>
      <FullWidthSection paddingTop={PaddingOption.NONE} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <FlexBox justify={Justification.START} wrap={false} className="download__download-activate-content">
            <div className="download__download-activate-content__image-container">
              <img src={downloadImage} alt="" className="download__download-activate-content__image-container__image" />
            </div>
            <div className="download__download-activate-content__text">
              <Heading
                headingLevel={HeadingLevel.H2}
                headingStyle={HeadingStyle.TITLE}
                className="download__download-activate-content__text__title"
              >
                Install
              </Heading>
              <Paragraph className="download__download-activate-content__text__subtitle">
                After your software has downloaded, install it on your machine.
              </Paragraph>
            </div>
          </FlexBox>
          <FlexBox justify={Justification.START} wrap={false} className="download__download-activate-content">
            <div className="download__download-activate-content__image-container">
              <img src={activateImage} alt="" className="download__download-activate-content__image-container__image" />
            </div>
            <div className="download__download-activate-content__text">
              <Heading
                headingLevel={HeadingLevel.H2}
                headingStyle={HeadingStyle.TITLE}
                className="download__download-activate-content__text__title"
              >
                Activate
              </Heading>
              <Paragraph className="download__download-activate-content__text__subtitle">
                After installing, open Audio Notetaker and enter your licence key.
              </Paragraph>
            </div>
          </FlexBox>
          <FlexBox justify={Justification.START} wrap={false} className="download__download-activate-content">
            <div className="download__download-activate-content__image-container">
              <img src={linkImage} alt="" className="download__download-activate-content__image-container__image" />
            </div>
            <div className="download__download-activate-content__text">
              <Heading
                headingLevel={HeadingLevel.H2}
                headingStyle={HeadingStyle.TITLE}
                className="download__download-activate-content__text__title"
              >
                Link
              </Heading>
              <Paragraph className="download__download-activate-content__text__subtitle">
                Now you’re set with the software, download and then log into the Link App using your registered email
                address and the final four digits of your licence key.
              </Paragraph>
            </div>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.SMALL} paddingBottom={PaddingOption.SMALL}>
        <ContentContainer>
          <FlexBox justify={Justification.AROUND} className="download__download-information">
            <a href="https://itunes.apple.com/gb/app/sonocent-link/id1138774924#?platform=iphone" target="__blank">
              <img src={appStoreImage} alt="Download on the App Store" className="download__app-store-image" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sonocent.sonocentrecorder.bundle&hl=en_GB"
              target="__blank"
            >
              <img src={googlePlayImage} alt="Get it on Google Play" className="download__app-store-image" />
            </a>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.TINY} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent
            title="Learn the basics with our Sprint Start video"
            subtitle="You’re set to go with Sonocent, now it’ll take just five minutes to learn the basics."
            center
            className="download__quick-start-video"
          >
            <Video
              src="https://player.vimeo.com/video/295555759"
              className="download__quick-start-video__video"
              id="download-quick-start-video"
            />
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.NONE} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent
            title="Want to make the most of Sonocent?"
            subtitle="You can learn more with our tutorial videos through our Sonocent Skills Portal."
            className="download__skills-portal"
          >
            <Button to="http://try.sonocent.com/skills-portal/" newTab>
              Skills Portal
            </Button>
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>
    </main>
  </MainLayout>
)

export default Download
